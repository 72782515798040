import React, { useState } from 'react'
import Logo from './assest/logo.png'
import bars from './assest/bars.png'
import './Header.css'
import { Link } from 'react-scroll/modules'
const Header = () => {
  const mobile = window.innerWidth <=768 ? true : false;
  const[menuOpen,setMenu]= useState(false);
  return (
  <div className="header" id='home'>
  <img src={Logo} alt="" className='logo' />

    {menuOpen === false && mobile === true?(
      <div style={{
        background :'var(--appColor)',
     padding:'0.56rem',
     borderRadius :'5px'
      }}
      onClick={()=>{
        setMenu(true)
      }}>
        <img src={bars} alt="" style={{
          width:'1.5rem',
          height :'1.5rem'
        }} />
      </div>
    ):(
      <ul className='header-menu'>
      <li>
      <Link
      onClick={()=>setMenu(false)}
      to='home'
      activeClass='default'
      span={true}
      smooth={true}>Home</Link></li>
      <li>
      <Link
      onClick={()=>setMenu(false)}
      to='programs'
      span={true}
      smooth={true}>Programs</Link></li>
      <li>
      <Link
      onClick={()=>setMenu(false)}
      to='reasons'
      span={true}
      smooth={true}>Why us</Link></li>
      <li>
      <Link
      onClick={()=>setMenu(false)}
      to='plans'
      span={true}
      smooth={true}>
        Plans</Link></li>
      <li ><Link
      onClick={()=>setMenu(false)}
      to='testimonials'
      span={true}
      smooth={true}>
        Testimonials</Link></li>
    </ul>
    )}
 
 
  </div>
  )
}

export default Header
