import './App.css';
import Hero from './componets/hero/Hero';
import Plans from './componets/Plans/Plans';
import Programs from './componets/programs/Programs';
import Reasons from './componets/Reasons/Reasons';
import Footer from './Footer/Footer';
import Join from './Join/Join';
import Testimonials from './Testimonials/Testimonials';
function App() {
  return (
    <div className="App">
      <Hero/>
      <Programs/>
      <Reasons/>
       <Plans/>
      <Testimonials/> 
      <Join/>
      <Footer/>
    </div>
  );
}

export default App;