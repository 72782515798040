import React, { useState } from 'react'
import './Testimonials.css'
import leftArrow from '../componets/hero/Header/assest/leftArrow.png'
import rightArrow from '../componets/hero/Header/assest/rightArrow.png'
import { motion } from 'framer-motion'

import {testimonialsData} from '../../src/componets/programs/data/testimonialsData'
const Testimonials = () => {
    const[selected,setSelected] =useState(0);
    const tLength = testimonialsData.length
    const transitions = {type:'spring', duration : 3}
  return (
  <div className="testimonials" id='testimonials'>
    <div className="left-t">
        <span>Testimonials</span>
        <span className='stroke-text'>What they</span>
        <span style={{color:'white'}}>Say about us?</span>
        <motion.span
        key={selected}
        initial={{opacity:0,x:-100}}
        animate ={{opacity:1,x:0}}
        exit={{opacity:0,x:100}}
        transition ={transitions}>
          {testimonialsData[selected].review}
        </motion.span>
        <span>
<span style={{color:"var(--orange)"}}>{testimonialsData[selected].name}
{"  "}</span>
            <span> - {testimonialsData[selected].status}</span>
        </span>
    </div>
    <div className="right-t">
        <motion.div
        initial ={{opacity:0, x:-100}}
        whileInView={{opacity:1, x:0}}
        transition={{...transitions, duration : 2}}></motion.div>
        <motion.div
        initial ={{opacity:0, x:100}}
        whileInView={{opacity:1, x:0}}
        transition={{...transitions, duration : 2}}></motion.div>
        <motion.img
        key={selected}
        initial ={{opacity:0, x:100}}
        animate ={{opacity:1,x:0}}
        exit ={{opacity:0,x:-100}}
        transition ={transitions} src={testimonialsData[selected].image} alt="" />
        <div className="arrows">
            <img onClick={()=>{
                selected === 0 ? setSelected(tLength - 1) : setSelected((prev)=>
                prev - 1)
            }} src={leftArrow} alt="" />
            <img onClick={()=>{
                selected === tLength -1 ? setSelected(0) : setSelected((prev)=>
                prev + 1)
            }} src={rightArrow} alt="" />
        </div>
    </div>
  </div>
  )
}

export default Testimonials
